@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);


body {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, rgba(129,199,132,1) 0%, rgba(63,81,181,1) 100%);
}

html, body { margin: 0; height: 100%; }

.App {
  padding: 30px;
  text-align: center;
}

.carousel {
  display: block;
  margin: auto;
  max-width: 600px;
}

.carouselButton {
  margin: 10px;
  padding: 4px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid white;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: white;
}

.youtubeContainer {
  position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.helpText {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
}

.center {
  text-align: center;
}

.logo {
  fill: white;
  height: 120px;
  width: 120px
}

.text {
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 34px;
}

.supportText {
  font-family: 'Montserrat', sans-serif;
  font-size: 17px;
  text-decoration: none;
}

.supportButton {
  display: block;
  margin: 0 auto;
  width: 180px;
  padding: 7px;
  border-radius: 4px;
  border: 1px solid white;
  color: white
}

.supportButton:hover {
  color: rgb(97, 97, 97);
  background-color: white;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
}

.header {
  color: rgb(240, 240, 240);
  font-family: 'Montserrat', sans-serif;
  font-size: 26px;
}

.tabsContent {
  max-width: 600px;
  overflow-y: scroll;
}

.textGrey {
  color: rgb(231, 231, 231);
  font-family: 'Montserrat', sans-serif;
  font-size: 34px;
}

.description {
  color: rgb(255, 255, 255);
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
}

.appleStore {
  height: 50px;
  margin: 10px;
}

.googlePlay {
  height: 50px;
  margin: 10px
}
